import(/* webpackMode: "eager" */ "/codebuild/output/src4004771040/src/willscot-frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4004771040/src/willscot-frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4004771040/src/willscot-frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4004771040/src/willscot-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4004771040/src/willscot-frontend/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/fonts/SairaCondensed/SairaCondensed-SemiBold.ttf\",\"weight\":\"600\",\"style\":\"semibold\"},{\"path\":\"../../../public/fonts/SairaCondensed/SairaCondensed-Medium.ttf\",\"weight\":\"500\",\"style\":\"medium\"},{\"path\":\"../../../public/fonts/SairaCondensed/SairaCondensed-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"}],\"variable\":\"--font-saira-condensed\"}],\"variableName\":\"sairaCondensed\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4004771040/src/willscot-frontend/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/fonts/SairaSemiCondensed/SairaSemiCondensed-SemiBold.ttf\",\"weight\":\"600\",\"style\":\"semibold\"},{\"path\":\"../../../public/fonts/SairaSemiCondensed/SairaSemiCondensed-Medium.ttf\",\"weight\":\"500\",\"style\":\"medium\"},{\"path\":\"../../../public/fonts/SairaSemiCondensed/SairaSemiCondensed-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"}],\"variable\":\"--font-saira-semi-condensed\"}],\"variableName\":\"sairaSemiCondensed\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4004771040/src/willscot-frontend/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/fonts/Saira/Saira-SemiBold.ttf\",\"weight\":\"600\",\"style\":\"semibold\"},{\"path\":\"../../../public/fonts/Saira/Saira-Medium.ttf\",\"weight\":\"500\",\"style\":\"medium\"},{\"path\":\"../../../public/fonts/Saira/Saira-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"}],\"variable\":\"--font-saira\"}],\"variableName\":\"saira\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src4004771040/src/willscot-frontend/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/codebuild/output/src4004771040/src/willscot-frontend/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4004771040/src/willscot-frontend/src/app/[lang]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4004771040/src/willscot-frontend/src/components/CallRailTrigger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4004771040/src/willscot-frontend/src/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4004771040/src/willscot-frontend/src/components/navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4004771040/src/willscot-frontend/src/components/ScriptComponent.tsx");
